import { FunctionComponent, useContext } from 'react';
import { StateContext } from '../GlobalState/GlobalState';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface IProps extends RouteProps {
  privileges: string[];
}

const ProtectedRoute: FunctionComponent<IProps> = ({ privileges, ...children }) => {
  const { state } = useContext(StateContext);

  if (!privileges.includes(state.userType)) return <Redirect to='/' />;
  return <Route {...children} />;
};

export default ProtectedRoute;
