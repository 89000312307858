import React, { useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface IProps {
  title: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  list: string[];
  required?: boolean;
}

const SelectField = React.forwardRef<HTMLInputElement, IProps>(({ list, title, required, value, setValue }, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='selector'>
      <div className='selectorField' onClick={() => setOpen(!open)}>
        <p className='selectorField-placeholder' ref={ref}>
          {value}
        </p>
        <span className='selectorField-title'>{title}</span>
        {open ? <FaCaretUp className='selectorField-icon' /> : <FaCaretDown className='selectorField-icon' />}
      </div>
      <ul className={open ? 'select-list' : 'select-list inactive'}>
        {list.map((val, index) => {
          return (
            <li
              className='select-item'
              key={index}
              onClick={() => {
                setValue(list[index]);
                setOpen(false);
              }}
            >
              {val}
            </li>
          );
        })}
      </ul>
      {required && <div className='required_indicator'>*</div>}
    </div>
  );
});

export default SelectField;
