import validator from 'validator';
import { INotification, SANITIZE_CHECK } from '../static/interfaces/globalInterfaces';

export async function validatePostClient(
  data: any,
  setNotification: (notification: INotification) => void
): Promise<boolean> {
  return new Promise((resolve, _) => {
    let errors: string[] = [];
    if (!data.name) errors.push('A Client Name is required');
    if (!validator.isLength(data.name, { min: 2, max: 200 }))
      errors.push('A Client Name must be between 2 and 200 characters');
    if (SANITIZE_CHECK.test(data.name)) errors.push('A Client Name contains invalid characters');

    if (errors.length > 0) {
      setNotification({ message: errors.join('\n'), type: 'error' });
      return resolve(false);
    } else {
      return resolve(true);
    }
  });
}
