import React, { useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import './SelectField.scss';

interface IProps {
  value: string[];
  title: string;
  required?: boolean;
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}

const SelectField = React.forwardRef<HTMLInputElement, IProps>(({ value, title, required, selected, setSelected }, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='selector'>
      <div className='selectorField' onClick={() => setOpen(!open)}>
        <p className='selectorField-placeholder' ref={ref}>
          {value[selected]}
        </p>
        <span className='selectorField-title'>{title}</span>
        {open ? <FaCaretUp className='selectorField-icon' /> : <FaCaretDown className='selectorField-icon' />}
      </div>
      <ul className={open ? 'select-list' : 'select-list inactive'}>
        {value.map((val, index) => {
          return (
            <li
              className='select-item'
              key={index}
              onClick={() => {
                setSelected(index);
                setOpen(false);
              }}
            >
              {val}
            </li>
          );
        })}
      </ul>
      {required && <div className='required_indicator'>*</div>}
    </div>
  );
});

export default SelectField;
