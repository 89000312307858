/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { baseURL, sendRequestV1, sendGetRequest } from '../../Hooks/useRequest';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import SelectField from '../../components/SelectField/SelectField';
import ViewField from '../../components/ViewField/ViewField';
import TextArea from '../../components/TextArea/TextArea';
import NotificationBanner from '../../components/Notification/Notification';
import ReactiveField from '../../components/ReactiveField/ReactiveField';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import { validatePostRlsValue } from '../../validations/rls';

const RlsValueCreate = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });
  const [stateReport, setStateReport] = useState(['Please select a Report']);
  const [stateList, setStateList] = useState({ client: [], office: [] });
  const [selectedReport, setSelectedReport] = useState(-1);
  const [stateRoles, setStateRoles] = useState([]);
  const [stateRolesSelected, setStateRolesSelected] = useState('No Available RLS Role');

  const roleNames = useRef<HTMLUListElement>(null);
  const report = useRef<HTMLInputElement>(null);
  const role = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      try {
        const reports = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/report?role=true`);
        setStateReport(reports.map((report: any) => report.name));
        setStateList({
          client: reports.map((report: any) => report.client),
          office: reports.map((report: any) => report.office),
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const rep = report.current?.innerText === 'Please select a Report' ? '' : report.current?.innerText;
        const client = stateList.client[selectedReport] === ' ' ? '' : stateList.client[selectedReport];
        const office = stateList.office[selectedReport] === ' ' ? '' : `&office=${stateList.office[selectedReport]}`;
        const url = `${baseURL}/api/v1/rls/role/${rep}?client=${client}${office}`;
        const roles = client.length > 1 ? await sendGetRequest(state, dispatch, encodeURI(url)) : [];

        setStateRoles(roles);
        setStateRolesSelected(roles.length === 0 ? 'No Available RLS Role' : roles[0]);
      } catch (e) {
        setStateRoles([]);
        setStateRolesSelected('No Available RLS Role');
      }
    })();
  }, [selectedReport]);

  return (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <h1 className='form-title'>Create RLS Value</h1>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <SelectField title='Reports' list={stateReport} indexTracker={setSelectedReport} ref={report} required={true} />
            <ViewField label={'Client Name'} value={stateList.client[selectedReport] || ' '} />
            <ViewField label={'Office Name'} value={stateList.office[selectedReport] || ' '} />
            <ReactiveField
              title='RLS Role'
              list={stateRoles}
              value={stateRolesSelected}
              setValue={setStateRolesSelected}
              ref={role}
              required={true}
            />
            <TextArea label={'Value Name'} ref={roleNames} required={true} />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={() =>
                sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Rls Value Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostRlsValue,
                  `${baseURL}/api/v1/rls/value`,
                  'POST',
                  {
                    names: roleNames.current?.innerText.split('\n').filter((val: string) => val !== ''),
                    reportId: report.current?.innerText,
                    clientId: stateList.client[selectedReport] || '',
                    officeId: stateList.office[selectedReport] === ' ' ? '' : stateList.office[selectedReport],
                    roleId: role.current?.innerText,
                  }
                )
              }
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(RlsValueCreate);
