import { useContext, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { sendRequestV1, baseURL } from '../../Hooks/useRequest';
import TextField from '../../components/TextField2/TextField';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import NotificationBanner from '../../components/Notification/Notification';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import { validatePutPersonal } from '../../validations/personal';

const Personal = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });

  const [firstNameState, setFirstNameState] = useState('');
  const [lastNameState, setLastNameState] = useState('');

  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);

  return (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <h1 className='form-title'>Personal Portal</h1>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <TextField
              type='text'
              label={'First Name'}
              ref={firstName}
              value={firstNameState}
              setValue={setFirstNameState}
              required={true}
            />
            <TextField
              type='text'
              label={'Last Name'}
              ref={lastName}
              value={lastNameState}
              setValue={setLastNameState}
              required={true}
            />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={() =>
                sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'User Updated Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any, results: any) => {
                      dispatch({ type: dispatchOptions.SetJWT, payload: jwt });
                      dispatch({
                        type: dispatchOptions.UpdateUser,
                        payload: {
                          firstName: results.firstName,
                          lastName: results.lastName,
                          clientId: state.client,
                          officeId: state.office,
                        },
                      });
                    },
                  },
                  state,
                  validatePutPersonal,
                  `${baseURL}/api/v1/user/${state.email}`,
                  'PUT',
                  {
                    firstName: firstName.current?.value,
                    lastName: lastName.current?.value,
                    clientId: state?.client,
                    officeId: state?.office,
                  }
                )
              }
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(Personal);
