import { RoleType } from '../interfaces/login';

export function getUserRoleList(role: string) {
  switch (role) {
    case RoleType.Superuser:
      return [capitalize(RoleType.Poweruser), capitalize(RoleType.ADMIN), capitalize(RoleType.Member)];
    case RoleType.Poweruser:
      return [capitalize(RoleType.ADMIN), capitalize(RoleType.Member)];
    case RoleType.ADMIN:
      return [capitalize(RoleType.Member)];
    default:
      return [];
  }
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
