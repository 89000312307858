import React, { useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { AiOutlineStop } from 'react-icons/ai';
import './SelectArea.scss';

interface IProps {
  list: string[];
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  title: string;
  required?: boolean;
}

const SelectField = React.forwardRef<HTMLUListElement, IProps>(({ list, title, required, values, setValues }, ref) => {
  const [open, setOpen] = useState(false);
  const available = list.length ? true : false;

  return (
    <div className='form-area-div'>
      <label className={list.length >= 1 ? 'form-area-label inactive' : 'form-area-label'}>{title}</label>
      <div className='form-area-container'>
        <ul className='form-area-list' ref={ref}>
          {values
            .filter((elem, i, arr) => i === arr.indexOf(elem))
            .map((val, index) => (
              <li className='form-area-list-item no-click-item' key={index}>
                {val}
              </li>
            ))}
        </ul>
        <div className='form-area-check-container'>
          {available ? (
            open ? (
              <FaCaretUp className='check-area-icon' onClick={() => setOpen(false)} />
            ) : (
              <FaCaretDown className='check-area-icon' onClick={() => setOpen(true)} />
            )
          ) : (
            <AiOutlineStop className='check-area-icon inactive' />
          )}
          <ul className={open ? 'select-list form-area-check-list' : 'select-list form-area-check-list inactive'}>
            {list.map((val, index) => (
              <li className='checkbox-item' key={index}>
                <input
                  type='checkbox'
                  className='form-area-checkbox'
                  key={index}
                  id={`${val}-${index}`}
                  onChange={element => {
                    element.target.checked ? setValues([...values, val]) : setValues(values.filter(elem => elem !== val));
                  }}
                />
                <label className='form-area-check-label' htmlFor={`${val}-${index}`}>
                  {val}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {required && <div className='required_indicator'>*</div>}
    </div>
  );
});

export default SelectField;
