/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { sendGetRequest, sendRequestV1, baseURL } from '../../Hooks/useRequest';
import TextField from '../../components/TextField2/TextField';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import NotificationBanner from '../../components/Notification/Notification';
import SelectField from '../../components/SelectField2/SelectField';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import { validatePostOffice } from '../../validations/office';
import { MdAdd, MdEdit } from 'react-icons/md';

const Office = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [modify, setModify] = useState(true);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });
  const [stateClient, setStateClient] = useState<string | string[]>(['Please select a Client']);
  const [refresh, setRefresh] = useState(false);
  const [offices, setOffices] = useState<any[]>([]);
  const [officeDropdown, setOfficeDropdown] = useState<any[]>([]);

  const [officeNameState, setOfficeNameState] = useState('');
  const [clientNameState, setClientNameState] = useState('');
  const [selectedOffice, setSelectedOffice] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);

  const office = useRef<HTMLInputElement>(null);
  const officeList = useRef<HTMLInputElement>(null);
  const client = useRef<HTMLInputElement>(null);

  const updateOffice = useCallback(() => {
    const index = stateClient.indexOf(offices[selectedOffice]?.clientId.name);
    setOfficeNameState(officeDropdown[selectedOffice]);
    setSelectedClient(index === -1 ? 0 : index);
  }, [selectedOffice, offices]);

  const resetOffice = useCallback(() => {
    setOfficeNameState('');
    setSelectedClient(0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res1 = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/me`);

        setStateClient(res1.client);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!modify) updateOffice();
  }, [selectedOffice, modify]);

  useEffect(() => {
    (async () => {
      try {
        const res1 = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/office`);

        setOfficeDropdown(res1.map((office: any) => office.name));
        setOffices(res1);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [refresh, modify]);

  return modify ? (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='two-form-header'>
            <h2 className='form-title'> Create New Office</h2>
            <div
              className='toggle-button'
              onClick={() => {
                if (officeDropdown.length > 0) {
                  updateOffice();
                  setModify(prev => !prev);
                  setNotification({ message: '', type: 'hidden' });
                } else {
                  setNotification({ message: 'No offices to modify', type: 'error' });
                }
              }}
            >
              <MdEdit />
            </div>
          </div>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <TextField
              type='text'
              label={'Office Name'}
              value={officeNameState}
              setValue={setOfficeNameState}
              ref={office}
              required={true}
            />
            {stateClient instanceof Array ? (
              <SelectField
                title='Client Name'
                value={stateClient}
                selected={selectedClient}
                setSelected={setSelectedClient}
                ref={client}
                required={true}
              />
            ) : (
              <TextField
                type='text'
                label='Client Name'
                value={clientNameState}
                setValue={setClientNameState}
                disabled={true}
                ref={client}
              />
            )}
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Office Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostOffice,
                  `${baseURL}/api/v1/office`,
                  'POST',
                  {
                    name: office.current?.value,
                    clientId: client.current?.innerText || state.client,
                  }
                );

                if (refresh) {
                  resetOffice();
                  setRefresh(prev => !prev);
                }
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  ) : (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='two-form-header'>
            <SelectField
              title='Offices'
              value={officeDropdown}
              ref={officeList}
              selected={selectedOffice}
              setSelected={setSelectedOffice}
            />
            <div
              className='toggle-button'
              onClick={() => {
                resetOffice();
                setModify(prev => !prev);
              }}
            >
              <MdAdd />
            </div>
          </div>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <TextField
              type='text'
              label={'Office Name'}
              ref={office}
              value={officeNameState}
              setValue={setOfficeNameState}
              required={true}
            />
            {stateClient instanceof Array ? (
              <SelectField
                title='Client Name'
                value={stateClient}
                selected={selectedClient}
                setSelected={setSelectedClient}
                ref={client}
                required={true}
              />
            ) : (
              <TextField
                type='text'
                label='Client Name'
                value={clientNameState}
                setValue={setClientNameState}
                disabled={true}
                ref={client}
              />
            )}
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Delete'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Office Removed Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  () => Promise.resolve(true),
                  `${baseURL}/api/v1/office/${officeDropdown[selectedOffice]}/${offices[selectedOffice].clientId.name}`,
                  'DELETE',
                  { name: office.current?.value }
                );
                if (refresh) {
                  setSelectedClient(0);
                  resetOffice();
                  setRefresh(prev => !prev);

                  if (officeDropdown.length === 1) {
                    setModify(prev => !prev);
                    setNotification({ message: '', type: 'hidden' });
                  }
                }
                return Promise.resolve();
              }}
            />
            <Button
              type='Update'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Office Updated Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostOffice,
                  `${baseURL}/api/v1/office/${officeDropdown[selectedOffice]}/${offices[selectedOffice].clientId.name}`,
                  'PUT',
                  {
                    name: office.current?.value,
                    clientId: client.current?.innerText || state.client,
                  }
                );
                if (refresh) {
                  setRefresh(prev => !prev);
                }
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(Office);
