/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { sendRequestV1, sendGetRequest, baseURL } from '../../Hooks/useRequest';
import TextField from '../../components/TextField/TextField';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import SelectField from '../../components/SelectField/SelectField';
import NotificationBanner from '../../components/Notification/Notification';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import { validatePostReport } from '../../validations/report';

const Report = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });
  const [stateClient, setStateClient] = useState<string | string[]>(['Please select a Client']);
  const [stateOffice, setStateOffice] = useState<string | string[]>(['Please select an Office']);
  const [stateToken, setStateToken] = useState(['Please select a Token']);

  const reportName = useRef<HTMLInputElement>(null);
  const reportId = useRef<HTMLInputElement>(null);
  const groupId = useRef<HTMLInputElement>(null);
  const client = useRef<HTMLInputElement>(null);
  const office = useRef<HTMLInputElement>(null);
  const token = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      try {
        const [res1, res2] = await Promise.all([
          sendGetRequest(state, dispatch, `${baseURL}/api/v1/me`),
          sendGetRequest(state, dispatch, `${baseURL}/api/v1/principalToken`),
        ]);

        setStateClient(res1.client);
        setStateOffice(res1.office);
        setStateToken(res2.map((t: any) => t.name));
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <h1 className='form-title'>Manage Reports</h1>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <TextField type='text' label={'Report Name'} ref={reportName} required={true} />
            <TextField type='text' label={'Report Id'} ref={reportId} required={true} />
            <TextField type='text' label='Group Id' ref={groupId} required={true} />
            {stateClient instanceof Array ? (
              <SelectField title='Client Name' list={stateClient} ref={client} required={true} />
            ) : (
              <TextField type='text' label='Client Name' value={stateClient} ref={client} />
            )}
            {stateOffice instanceof Array ? (
              <SelectField title='Office Name' list={stateOffice} ref={office} />
            ) : (
              <TextField type='text' label='Office Name' value={stateOffice} ref={office} />
            )}
            <SelectField title='Token Name' list={stateToken} ref={token} required={true} />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={() =>
                sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Report Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostReport,
                  `${baseURL}/api/v1/report`,
                  'POST',
                  {
                    name: reportName.current?.value,
                    reportId: reportId.current?.value,
                    groupId: groupId.current?.value,
                    clientId: client.current?.innerText,
                    officeId: office.current?.innerText,
                    principalToken: token.current?.innerText,
                  }
                )
              }
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(Report);
