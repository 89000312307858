/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, lazy, useEffect, useContext, useState } from 'react';
import LoadSpinner from '../../Resources/LoadSpinner/LoadSpinner';
import { withRouter } from 'react-router-dom';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { useRetrieveReportTokens } from '../../Hooks/useRequest';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
const TabComponent = lazy(() => import('../../components/Tab/Tabs'));

const Home = () => {
  const [mount, setMount] = useState(true);
  const { state, dispatch } = useContext(StateContext);
  const [timeout, finished] = useRetrieveReportTokens(mount);

  useEffect(() => {
    return () => {
      setMount(false);
      if (state.timeout || timeout) {
        state.timeout && clearInterval(state.timeout);
        timeout && clearInterval(timeout);
        dispatch({ type: dispatchOptions.SetTimeout, payload: null });
      }
    };
  }, [timeout]);

  return finished ? (
    <div className='page'>
      <Suspense fallback={<LoadSpinner size={4} duration={1.5} />}>
        <TabComponent />
      </Suspense>
    </div>
  ) : (
    <div className='page'>
      <LoadSpinner size={4} duration={1.5} />
    </div>
  );
};

export default withRouter(Home);
