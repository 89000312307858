import validator from 'validator';
import { INotification, SANITIZE_CHECK } from '../static/interfaces/globalInterfaces';

export async function validatePostUser(data: any, setNotification: (notification: INotification) => void): Promise<boolean> {
  return new Promise((resolve, _) => {
    let errors: string[] = [];
    if (!data.firstName) errors.push('First Name is required');
    if (!validator.isLength(data.firstName, { min: 2, max: 200 }))
      errors.push('First Name must be between 2 and 200 characters');
    if (SANITIZE_CHECK.test(data.firstName)) errors.push('First Name contains invalid characters');
    if (!data.lastName) errors.push('Last Name is required');
    if (!validator.isLength(data.lastName, { min: 2, max: 200 }))
      errors.push('Last Name must be between 2 and 200 characters');
    if (SANITIZE_CHECK.test(data.lastName)) errors.push('Last Name contains invalid characters');
    if (!data.email) errors.push('Email is required');
    if (!validator.isEmail(data.email)) errors.push('Email is invalid');
    if (!data.clientId || data.clientId === 'Please Select a Client') errors.push('Client is required');
    if (!data.officeId) errors.push('Office is required');
    if (!data.userType) errors.push('Role is required');

    if (errors.length > 0) {
      setNotification({ message: errors.join('\n'), type: 'error' });
      return resolve(false);
    } else {
      return resolve(true);
    }
  });
}

export async function validatePostUserReport(
  data: any,
  setNotification: (notification: INotification) => void
): Promise<boolean> {
  return new Promise((resolve, _) => {
    let errors: string[] = [];
    if (!data.email) errors.push('Email is required');
    if (!validator.isEmail(data.email)) errors.push('Email is invalid');
    if (!data.reportId) errors.push('Report is required');
    if (!data.reportClientId || data.reportClientId === 'Please Select a Client') errors.push('Report Client is required');
    if (!data.rlsRoleId) errors.push('Role is required');
    if (!data.rlsRoleId) errors.push('Role is required');
    if (data.rlsValues.length > 0) {
      if (!data.rlsValues.map((name: string) => validator.isLength(name, { min: 2, max: 200 })).every((val: any) => val))
        errors.push('All RLS Value must be between 2 and 200 characters');
      if (data.rlsValues.map((name: string) => SANITIZE_CHECK.test(name)).some((test: boolean) => test))
        errors.push(`At least one RLS Value contains an invalid characters`);
    }

    if (errors.length > 0) {
      setNotification({ message: errors.join('\n'), type: 'error' });
      return resolve(false);
    } else {
      return resolve(true);
    }
  });
}
