import Light_Logo from '../../static/img/Light_Logo.png';
import Dark_Logo from '../../static/img/Dark_Logo.png';
import { darkTheme, lightTheme } from '../../static/styles/GlobalStyle';
import { IGlobalUserState } from '../../static/interfaces/globalInterfaces';

export enum dispatchOptions {
  SetUser = 'SET_USER',
  UpdateUser = 'Update_USER',
  SetTheme = 'SET_THEME',
  SetTokenExpiration = 'SET_TOKEN_EXPIRATION',
  SetTimeout = 'SET_TIMEOUT',
  SetKeycloakToken = 'SET_KEYCLOAK_TOKEN',
  SetReportList = 'SET_REPORT _LIST',
  SetActiveTab = 'SET_ACTIVE_TAB',
  SetTab = 'SET_TAB',
  SetLoaded = 'SET_LOADED',
  SetRefresh = 'SET_REFRESH',
  SetDropdown = 'SET_DROPDOWN',
  SetDropdownHeight = 'SET_DROPDOWN_HEIGHT',
  SetExpandedSidebar = 'SET_EXPANDED_SIDEBAR',
  SetCSRF = 'SET_CSRF',
  SetJWT = 'SET_JWT',
}

function StateFunction(state: IGlobalUserState, action: { type: dispatchOptions; payload: any }): IGlobalUserState {
  let { type, payload } = action;

  switch (type) {
    case dispatchOptions.SetUser:
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        userType: payload.userType,
        client: payload.clientName,
        office: payload.officeName,
        activeTab: 0,
        refresh: 0,
        loaded: false,
        dropdownOpen: false,
        expandSidebar: true,
        dropdownHeight: 0,
      };
    case dispatchOptions.UpdateUser:
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName,
      };
    case dispatchOptions.SetTheme:
      return {
        ...state,
        theme: payload,
        dashboard: payload ? lightTheme : darkTheme,
        logo: payload ? Light_Logo : Dark_Logo,
      };
    case dispatchOptions.SetTokenExpiration:
      return {
        ...state,
        principalToken_expiration: payload,
      };
    case dispatchOptions.SetTimeout:
      return {
        ...state,
        timeout: payload,
      };
    case dispatchOptions.SetKeycloakToken:
      return {
        ...state,
        keycloak: payload,
      };
    case dispatchOptions.SetReportList:
      return {
        ...state,
        reportList: payload,
      };
    case dispatchOptions.SetActiveTab:
      return {
        ...state,
        activeTab: payload,
      };
    case dispatchOptions.SetTab:
      return {
        ...state,
        tab: payload,
      };
    case dispatchOptions.SetLoaded:
      return {
        ...state,
        loaded: payload,
      };
    case dispatchOptions.SetJWT:
      return {
        ...state,
        jwtToken: payload,
      };
    case dispatchOptions.SetCSRF:
      return {
        ...state,
        csrfToken: payload,
      };
    case dispatchOptions.SetRefresh:
      return {
        ...state,
        refresh: state.refresh + 1,
      };
    case dispatchOptions.SetDropdown:
      return {
        ...state,
        dropdownOpen: payload,
      };
    case dispatchOptions.SetExpandedSidebar:
      return {
        ...state,
        expandSidebar: payload,
      };
    case dispatchOptions.SetDropdownHeight:
      return {
        ...state,
        dropdownHeight: payload,
      };
  }
}

export const reducer = StateFunction;
export const defaultState = {} as IGlobalUserState;
