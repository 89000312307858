import validator from 'validator';
import { INotification, SANITIZE_CHECK } from '../static/interfaces/globalInterfaces';

export async function validatePostRlsRole(
  data: any,
  setNotification: (notification: INotification) => void
): Promise<boolean> {
  return new Promise((resolve, _) => {
    let errors: string[] = [];
    if (!data.names.length) errors.push('At least one RLS Role is required');
    if (!data.names.map((name: string) => validator.isLength(name, { min: 2, max: 200 })).every((val: any) => val))
      errors.push('All RLS Roles must be between 2 and 200 characters');
    if (data.names.map((name: string) => SANITIZE_CHECK.test(name)).some((test: boolean) => test))
      errors.push(`At least one RLS Role contains an invalid characters`);
    if (!data.reportId || data.reportId === 'Please Select a Report') errors.push('Report is required');
    if (!data.clientId) errors.push('Client is required');
    if (!data.officeId) errors.push('Office is required');
    if (SANITIZE_CHECK.test(data.officeId)) errors.push('Office contains an invalid characters');

    if (errors.length > 0) {
      setNotification({ message: errors.join('\n'), type: 'error' });
      return resolve(false);
    } else {
      return resolve(true);
    }
  });
}

export async function validatePostRlsValue(
  data: any,
  setNotification: (notification: INotification) => void
): Promise<boolean> {
  return new Promise((resolve, _) => {
    let errors: string[] = [];
    if (!data.names.length) errors.push('At least one RLS Value is required');
    if (!data.names.map((name: string) => validator.isLength(name, { min: 2, max: 200 })).every((val: any) => val))
      errors.push('All RLS Values must be between 2 and 200 characters');
    if (data.names.map((name: string) => SANITIZE_CHECK.test(name)).some((test: boolean) => test))
      errors.push(`At least one RLS Value contains an invalid characters`);
    if (!data.reportId || data.reportId === 'Please Select a Report') errors.push('Report is required');
    if (!data.clientId) errors.push('Client is required');
    if (SANITIZE_CHECK.test(data.officeId)) errors.push('Office contains an invalid characters');
    if (!data.roleId) errors.push('RLS Role is required');
    if (SANITIZE_CHECK.test(data.roleId)) errors.push('RLS Role contains an invalid characters');

    if (errors.length > 0) {
      setNotification({ message: errors.join('\n'), type: 'error' });
      return resolve(false);
    } else {
      return resolve(true);
    }
  });
}
