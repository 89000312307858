import { IReport } from '../../static/interfaces/globalInterfaces';
import { FcComboChart } from 'react-icons/fc';
import bullhorn_dark from '../../static/img/bullhorn_black.svg';
import bullhorn_light from '../../static/img/bullhorn_white.svg';
import lab_coat_dark from '../../static/img/lab_coat_black.svg';
import schedule_dark from '../../static/img/schedule_black.svg';
import schedule_light from '../../static/img/schedule_white.svg';
import upstairs_dark from '../../static/img/upstairs_black.svg';
import upstairs_light from '../../static/img/upstairs_white.svg';
import axios from 'axios';

export function ReportLogoParser(report: string, theme: boolean) {
  switch (report) {
    case 'Clinic Director':
      return <img src={lab_coat_dark} alt='lab_coat' />;

    case 'Executive':
      return theme ? <img src={upstairs_dark} alt='upstairs' /> : <img src={upstairs_light} alt='upstairs' />;

    case 'Marketing':
      return theme ? <img src={bullhorn_dark} alt='bullhorn' /> : <img src={bullhorn_light} alt='bullhorn' />;
    case 'Office Manager':
      return theme ? <img src={schedule_dark} alt='schedule' /> : <img src={schedule_light} alt='schedule' />;
    default:
      return <FcComboChart />;
  }
}

export function initTabHeaders(num: number) {
  const date = new Date();
  const template = { embed_url: '', report_name: '', report_id: '', token: '', token_expiration: date };
  let temp = [];
  for (let i = 0; i < num; i++) {
    temp.push(template);
  }
  return temp;
}

export async function GenerateURLNameObj(reportArr: IReport[], email: string) {
  try {
    const filter = (await FetchReportName(reportArr, email)).filter(val => val[0] && val[1] !== undefined);
    const sorted = filter ? filter.sort((a, b) => a[1].localeCompare(b[1])) : undefined;

    const reports = sorted
      ? sorted.map(list => ({
          embed_url: list[0],
          report_name: list[1],
          report_id: list[2],
          token: list[3],
          token_expiration: list[4],
        }))
      : undefined;

    return Promise.resolve(reports);
  } catch (e) {
    console.error(e);
  }
}

async function FetchReportName(reportArr: IReport[], email: string) {
  if (reportArr.map(val => val.principalToken).filter(val => !!val).length === reportArr.length) {
    return Promise.all(
      reportArr.map(async report => {
        try {
          const [embedUrl, datasetId, name, id] = await GenerateEmbedURL(report);
          const [token, expiration] = await GenerateReportTokenInGroup(report, email, datasetId);
          return Promise.resolve([embedUrl, name, id, token, expiration]);
        } catch (e) {
          return Promise.reject(e);
        }
      })
    );
  }
  return [[]];
}

async function GenerateEmbedURL(report: IReport) {
  if (report.principalToken) {
    try {
      const url = `https://api.powerbi.com/v1.0/myorg/groups/${report.groupId}/reports/${report.reportId}`;
      const res = await axios(url, {
        headers: {
          Authorization: `Bearer ${report.principalToken}`,
        },
      });

      const result = res.data;
      return Promise.resolve([result.embedUrl, result.datasetId, result.name, result.id]);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return [];
}

async function GenerateReportTokenInGroup(report: IReport, email: string, datasetId: string) {
  if (report.principalToken) {
    try {
      const url = `https://api.powerbi.com/v1.0/myorg/groups/${report.groupId}/reports/${report.reportId}/GenerateToken`;
      const res = await (
        await fetch(url, {
          method: 'POST',
          headers: { Authorization: `Bearer ${report.principalToken}`, 'Content-Type': 'application/json' },
          body: JSON.stringify({
            accessLevel: 'View',
            allowSaveAs: 'true',
            identities: [
              {
                username: email,
                roles: [report.role],
                datasets: [datasetId],
              },
            ],
          }),
        })
      ).json();
      return Promise.resolve([res.token, res.expiration]);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return [];
}
