import './footer.scss';

const Footer = () => {
  return (
    <footer>
      <a className='link' href='https://www.aptmanagementsolutions.com/' target='blank'>
        <pre>Copyright &copy; 2022 by APT Management</pre>
        {/* <pre>
              All Rights Reserved.
            </pre> */}
      </a>
    </footer>
  );
};

export default Footer;
