import { useState, useContext } from 'react';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { powerPrivilege, adminPrivilege, superPrivilege } from '../../interfaces/login';
import { NavDropdownItem } from '../Dropdown/Dropdown';
import { Link } from 'react-router-dom';
import { userDropdownItems } from '../../Pages/User/items';
import { reportDropdownItems } from '../../Pages/Report/items';
import { rlsDropdownItems } from '../../Pages/RLS/items';
import './Navbar.scss';

const Navbar = () => {
  const { state } = useContext(StateContext);
  const [userDropdown, setUserDropdown] = useState(false);
  const [reportDropdown, setReportDropdown] = useState(false);
  const [rlsDropdown, setRlsDropdown] = useState(false);

  return (
    <>
      <nav className='navbar'>
        <ul className={'nav-menu'}>
          <Link to='/personal'>
            <li className='nav-item'>Personal</li>
          </Link>
          {superPrivilege.includes(state.userType) && (
            <Link to='/client'>
              <li className='nav-item'>Manage Clients</li>
            </Link>
          )}
          {powerPrivilege.includes(state.userType) && (
            <Link to='/office'>
              <li className='nav-item'>Manage Offices</li>
            </Link>
          )}
          {adminPrivilege.includes(state.userType) && (
            <li className='nav-item' onMouseEnter={() => setUserDropdown(true)} onMouseLeave={() => setUserDropdown(false)}>
              Users Services
              {userDropdown && <NavDropdownItem list={userDropdownItems(state.userType)} />}
            </li>
          )}
          {superPrivilege.includes(state.userType) && (
            <li
              className='nav-item'
              onMouseEnter={() => setReportDropdown(true)}
              onMouseLeave={() => setReportDropdown(false)}
            >
              Report Services
              {reportDropdown && <NavDropdownItem list={reportDropdownItems(state.userType)} />}
            </li>
          )}
          {superPrivilege.includes(state.userType) && (
            <li className='nav-item' onMouseEnter={() => setRlsDropdown(true)} onMouseLeave={() => setRlsDropdown(false)}>
              RLS Services
              {rlsDropdown && <NavDropdownItem list={rlsDropdownItems(state.userType)} />}
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
