import { KeycloakInstance } from 'keycloak-js';
import { IPowerBiParams, IReport } from '../static/interfaces/globalInterfaces';
import { dashboardTheme } from '../static/styles/GlobalStyle';

export enum RoleType {
  Superuser = 'superuser',
  Poweruser = 'poweruser',
  ADMIN = 'admin',
  Member = 'member',
}

export interface ILogin {
  firstName: string;
  lastName: string;
  email: string;
  userType: RoleType;
  clientName?: string;
  officeName?: string;
  theme: boolean;
  reports: IReport[];
  tab: IPowerBiParams[];
  dashboard: dashboardTheme;
  logo: string;
  activeTab?: number;
  refresh: number;
  loaded?: boolean;
  dropdownOpen?: boolean;
  expandSidebar?: boolean;
  dropdownHeight?: number;
}

export interface IAuthenticationTokens {
  csrfToken: string;
  jwtToken: string;
}

export interface IRefreshReports {
  principalToken: { token: string; expiration: number };
  timeout?: NodeJS.Timeout;
}

export interface IGlobalState extends ILogin, IAuthenticationTokens, IRefreshReports {
  keycloak: KeycloakInstance;
}

export const superPrivilege = [RoleType.Superuser.valueOf()];
export const powerPrivilege = [RoleType.Superuser.valueOf(), RoleType.Poweruser.valueOf()];
export const adminPrivilege = [RoleType.Superuser.valueOf(), RoleType.Poweruser.valueOf(), RoleType.ADMIN.valueOf()];
