import { RoleType } from '../../interfaces/login';

export function reportDropdownItems(role: string) {
  const list = [
    {
      id: 1,
      title: 'Manage Tokens',
      path: '/token',
    },
    {
      id: 2,
      title: 'Manage Reports',
      path: '/report',
    },
  ];

  if (role === RoleType.Superuser) return list;
  return [];
}
