import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { QueryClient, QueryClientProvider } from 'react-query';

const keycloak = Keycloak({
  url: 'https://aptmanagement-keycloak.azurewebsites.net/auth/',
  realm: 'APTManagement',
  clientId: 'datastore',
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
