import './Button.scss';

interface IProps {
  type: 'Submit' | 'Update' | 'Delete';
  active: boolean;
  action?: () => Promise<void>;
}
const Button: React.FC<IProps> = ({ type, active, action }) => {
  return (
    <>
      {action ? (
        <button className={active ? `form-btn ${type} active` : `form-btn ${type}`} disabled={active} onClick={action}>
          <span>{type}</span>
        </button>
      ) : (
        <button className={active ? `form-btn ${type} active` : `form-btn ${type}`} disabled={active}>
          <span>{type}</span>
        </button>
      )}
    </>
  );
};

export default Button;
