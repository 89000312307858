/* eslint-disable react-hooks/exhaustive-deps */
import Light_Logo from '../../static/img/Light_Logo.png';
import Dark_Logo from '../../static/img/Dark_Logo.png';
import { ThemeProvider } from 'styled-components';
import { useReducer, useEffect, createContext, useState } from 'react';
import { IGlobalUserState } from '../../static/interfaces/globalInterfaces';
import { reducer, defaultState } from './GlobalStateLogic';
import { login } from '../../Hooks/useRequest';
import { darkTheme, GlobalStyle, lightTheme } from '../../static/styles/GlobalStyle';
import { initTabHeaders } from '../Tab/TabsLogic';
import LoadSpinner from '../../Resources/LoadSpinner/LoadSpinner';

export const StateContext = createContext<{ state: IGlobalUserState; dispatch: any }>({
  state: defaultState,
  dispatch: reducer,
});

const GlobalState = (props: any) => {
  const [finished, setFinished] = useState(false);
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    (async () => {
      try {
        state.keycloak = props.keycloak;
        const [user, authTokens] = await login(state.keycloak.tokenParsed?.sub as string);

        state.firstName = user.firstName;
        state.lastName = user.lastName;
        state.email = user.email;
        state.userType = user.userType;
        state.client = user.clientId?.name;
        state.office = user.officeId?.name;
        state.theme = user.theme;
        state.dashboard = user.theme ? lightTheme : darkTheme;
        state.logo = user.theme ? Light_Logo : Dark_Logo;
        state.activeTab = 0;
        state.tab = initTabHeaders(1);
        state.refresh = 0;
        state.loaded = false;
        state.dropdownOpen = false;
        state.expandSidebar = true;
        state.dropdownHeight = 0;
        state.principalToken_expiration = 0;
        state.csrfToken = authTokens.csrfToken;
        state.jwtToken = authTokens.jwtToken;
      } catch (e) {
        console.error(e);
      }
      setFinished(true);
    })();
  }, []);

  return finished ? (
    <StateContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={state.dashboard}>
        <GlobalStyle />
        {props.children}
      </ThemeProvider>
    </StateContext.Provider>
  ) : (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyle />
      <div className='fullscreen'>
        <LoadSpinner size={4} duration={1.5} />
      </div>
    </ThemeProvider>
  );
};

export default GlobalState;
