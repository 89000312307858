import './loadSpinner.scss';
import { CgSpinner } from 'react-icons/cg';

interface Props {
  size: any;
  duration: number;
}

const LoadSpinner = ({ size, duration }: Props) => {
  return (
    <div className='load_spinner'>
      <CgSpinner className='spin' style={{ height: `${size}rem`, width: `${size}rem`, animationDuration: `${duration}s` }} />
    </div>
  );
};

export default LoadSpinner;
