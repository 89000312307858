import React, { useState } from 'react';

interface IProps {
  label: string;
  required?: boolean;
}

const TextArea = React.forwardRef<HTMLUListElement, IProps>(({ label, required }, ref) => {
  const [text, setText] = useState('');
  const [list, setList] = useState<string[]>([]);

  if (list.length >= 1) required = false;

  return (
    <div className='form-area-div'>
      <label className={list.length >= 1 ? 'form-area-label inactive' : 'form-area-label'}>{label}</label>
      <div className='form-area-container'>
        <ul className='form-area-list' ref={ref}>
          {list.map((val, index) => {
            return (
              <li
                className='form-area-list-item'
                key={index}
                onDoubleClick={() => {
                  setList(list.filter((_, i) => i !== index));
                }}
              >
                {val}
              </li>
            );
          })}
        </ul>
        <div className='form-area-input-container'>
          <input
            type='text'
            className='form-area-input'
            value={text}
            placeholder={' '}
            onChange={e => setText(e.target.value)}
          />
          <button
            className='form-area-button'
            onClick={e => {
              e.preventDefault();
              if (text && list.includes(text) === false) {
                setList([...list, text]);
                setText('');
              } else {
                setText('');
              }
            }}
          >
            +
          </button>
        </div>
      </div>
      {required && <div className='required_indicator'>*</div>}
    </div>
  );
});

export default TextArea;
