import { useContext, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { sendRequestV1, baseURL, sendGetRequest } from '../../Hooks/useRequest';
import TextField from '../../components/TextField2/TextField';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import SelectField from '../../components/SelectField2/SelectField';
import { MdAdd, MdEdit } from 'react-icons/md';
import NotificationBanner from '../../components/Notification/Notification';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import { validatePostClient } from '../../validations/client';

const Client = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [modify, setModify] = useState(true);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });
  const [clientDropdown, setClientDropdown] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [clientState, setClientState] = useState('');

  const client = useRef<HTMLInputElement>(null);
  const clientList = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!modify) {
      setClientState(clientDropdown[selectedClient]);
    }
  }, [selectedClient, modify]);

  useEffect(() => {
    (async () => {
      try {
        const res1 = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/client`);

        setClientDropdown(res1.map((client: any) => client.name));
      } catch (err) {
        console.error(err);
      }
    })();
  }, [refresh, modify]);

  return modify ? (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='one-form-header'>
            <h2 className='form-title'> Create New Client</h2>
            <div
              className='toggle-button'
              onClick={() => {
                if (clientDropdown.length > 0) {
                  setClientState(clientDropdown[selectedClient]);
                  setModify(prev => !prev);
                  setNotification({ message: '', type: 'hidden' });
                } else {
                  setNotification({ message: 'No clients to modify', type: 'error' });
                }
              }}
            >
              <MdEdit />
            </div>
          </div>
          <form className='one_column_form' onSubmit={e => e.preventDefault()}>
            <TextField
              type='text'
              label={'Client Name'}
              ref={client}
              value={clientState}
              setValue={setClientState}
              required={true}
            />
          </form>
          <div className='one-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Client Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostClient,
                  `${baseURL}/api/v1/client`,
                  'POST',
                  { name: client.current?.value }
                );
                if (refresh) {
                  setClientState('');
                  setRefresh(prev => !prev);
                }
                return Promise.resolve();
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  ) : (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='one-form-header'>
            <SelectField
              title='Clients'
              value={clientDropdown}
              ref={clientList}
              selected={selectedClient}
              setSelected={setSelectedClient}
            />
            <div
              className='toggle-button'
              onClick={() => {
                setClientState('');
                setModify(prev => !prev);
              }}
            >
              <MdAdd />
            </div>
          </div>
          <form className='one_column_form' onSubmit={e => e.preventDefault()}>
            <TextField
              type='text'
              label={'Client Name'}
              ref={client}
              required={true}
              value={clientState}
              setValue={setClientState}
            />
          </form>
          <div className='one-column-button-container'>
            <Button
              type='Delete'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Client Removed Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  () => Promise.resolve(true),
                  `${baseURL}/api/v1/client/${clientDropdown[selectedClient]}`,
                  'DELETE',
                  { name: client.current?.value }
                );
                if (refresh) {
                  setClientState('');
                  setSelectedClient(0);
                  setRefresh(prev => !prev);

                  if (clientDropdown.length === 1) {
                    setModify(prev => !prev);
                    setNotification({ message: '', type: 'hidden' });
                  }
                }
                return Promise.resolve();
              }}
            />
            <Button
              type='Update'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Client Updated Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostClient,
                  `${baseURL}/api/v1/client/${clientDropdown[selectedClient]}`,
                  'PUT',
                  { name: client.current?.value }
                );
                if (refresh) {
                  setRefresh(prev => !prev);
                }
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(Client);
