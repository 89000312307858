import { RoleType } from '../../interfaces/login';

export function userDropdownItems(role: string) {
  const list = [
    {
      id: 1,
      title: 'Manage User',
      path: '/user',
    },
    {
      id: 2,
      title: 'Assign Report',
      path: '/report-user',
    },
  ];

  if (role === RoleType.Member) return [];
  return list;
}
