import './sidebar.scss';
import Footer from '../Footer/Footer';
import { FaArrowRight, FaUserShield } from 'react-icons/fa';
import { IoSunnySharp, IoMoonSharp, IoLogOutSharp, IoHomeSharp } from 'react-icons/io5';
import { Dropdown, DropdownItem, DropdownLabel, DropdownMenu } from './../Dropdown/Dropdown';
import { useContext } from 'react';
import { dispatchOptions } from '../GlobalState/GlobalStateLogic';
import { StateContext } from '../GlobalState/GlobalState';
import { RoleType } from '../../interfaces/login';
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
  const { state, dispatch } = useContext(StateContext);
  const location = useLocation<Window>();

  return (
    <aside>
      {state.expandSidebar ? (
        <>
          <div className='company_logo'>
            <img
              onClick={() => dispatch({ type: dispatchOptions.SetExpandedSidebar, payload: !state.expandSidebar })}
              id='logo'
              src={state.logo}
              alt={`${state.client} Logo`}
            />
          </div>
          <div className='user_dropdown' style={{ marginBottom: `${state.dropdownHeight}px` }}>
            <Dropdown>
              <DropdownLabel>
                <DropdownMenu>
                  {state.userType !== RoleType.Member ? (
                    location.pathname !== '/' ? (
                      <DropdownItem logo={<IoHomeSharp />} title={'Home'} link={'/'} />
                    ) : (
                      <DropdownItem logo={<FaUserShield />} title={'Admin'} link={'/personal'} />
                    )
                  ) : undefined}
                  {state.theme ? (
                    <DropdownItem
                      logo={<IoMoonSharp />}
                      onClick={() => dispatch({ type: dispatchOptions.SetTheme, payload: !state.theme })}
                      title={'Dark Mode'}
                    />
                  ) : (
                    <DropdownItem
                      logo={<IoSunnySharp />}
                      onClick={() => dispatch({ type: dispatchOptions.SetTheme, payload: !state.theme })}
                      title={'Light Mode'}
                    />
                  )}
                  <DropdownItem logo={<IoLogOutSharp />} onClick={() => state.keycloak.logout()} title={'Log Out'} />
                </DropdownMenu>
              </DropdownLabel>
            </Dropdown>
          </div>
          <Footer />
        </>
      ) : (
        <>
          <FaArrowRight
            onClick={() => dispatch({ type: dispatchOptions.SetExpandedSidebar, payload: !state.expandSidebar })}
            id='expand_arrow'
          />
        </>
      )}
    </aside>
  );
};

export default Sidebar;
