/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { sendGetRequest, sendRequestV1, baseURL } from '../../Hooks/useRequest';
import { getUserRoleList } from '../../helpers/misc';
import TextField from '../../components/TextField2/TextField';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import SelectField from '../../components/SelectField2/SelectField';
import NotificationBanner from '../../components/Notification/Notification';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import { validatePostUser } from '../../validations/user';
import { MdAdd, MdEdit } from 'react-icons/md';

const User = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });
  const [stateClient, setStateClient] = useState<string | string[]>(['']);
  const [stateOffice, setStateOffice] = useState<string | string[]>(['']);
  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [userDropdown, setUserDropdown] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState(0);
  const [modify, setModify] = useState(true);
  const roleList = getUserRoleList(state.userType);

  const [firstNameState, setFirstNameState] = useState('');
  const [lastNameState, setLastNameState] = useState('');
  const [emailState, setEmailState] = useState('');

  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedOffice, setSelectedOffice] = useState(0);
  const [selectedRole, setSelectedRole] = useState(0);

  const userList = useRef<HTMLInputElement>(null);
  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const client = useRef<HTMLInputElement>(null);
  const office = useRef<HTMLInputElement>(null);
  const role = useRef<HTMLInputElement>(null);

  const updateUser = useCallback(() => {
    const roleIndex = roleList.findIndex(role => role.toLowerCase() === users[selectedUser].role);

    setFirstNameState(users[selectedUser].firstName);
    setLastNameState(users[selectedUser].lastName);
    setEmailState(users[selectedUser].email);
    setSelectedRole(roleIndex === -1 ? 0 : roleIndex);

    if (stateClient instanceof Array) {
      const clientIndex = stateClient.findIndex(client => client === users[selectedUser].client);
      setSelectedClient(clientIndex === -1 ? 0 : clientIndex);
    }
    if (stateOffice instanceof Array) {
      const officeIndex = stateOffice.findIndex(office => office === users[selectedUser].office);
      setSelectedOffice(officeIndex === -1 ? 0 : officeIndex);
    }
  }, [selectedUser, users]);

  const resetUser = useCallback(() => {
    setFirstNameState('');
    setLastNameState('');
    setEmailState('');
    setSelectedClient(0);
    setSelectedOffice(0);
    setSelectedRole(0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res1 = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/me`);

        setStateClient(res1.client);
        setStateOffice(res1.office);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!modify) updateUser();
  }, [selectedUser, modify]);

  useEffect(() => {
    (async () => {
      try {
        let res1 = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/user`);
        res1 = res1.filter((user: any) => user.role !== 'superuser');

        setUserDropdown(res1.map((user: any) => user.email));
        setUsers(res1);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [refresh, modify]);

  return modify ? (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='two-form-header'>
            <h2 className='form-title'> Create New User</h2>
            <div
              className='toggle-button'
              onClick={() => {
                if (userDropdown.length > 0) {
                  updateUser();
                  setModify(prev => !prev);
                  setNotification({ message: '', type: 'hidden' });
                } else {
                  setNotification({ message: 'No users to modify', type: 'error' });
                }
              }}
            >
              <MdAdd />
            </div>
          </div>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <TextField
              type='text'
              label={'First Name'}
              ref={firstName}
              value={firstNameState}
              setValue={setFirstNameState}
              required={true}
            />
            <TextField
              type='text'
              label={'Last Name'}
              ref={lastName}
              value={lastNameState}
              setValue={setLastNameState}
              required={true}
            />
            <TextField type='email' label='Email' ref={email} value={emailState} setValue={setEmailState} required={true} />
            {stateClient instanceof Array ? (
              <SelectField
                title='Client Name'
                value={stateClient}
                selected={selectedClient}
                setSelected={setSelectedClient}
                ref={client}
                required={true}
              />
            ) : (
              <TextField type='text' label='Client Name' value={stateClient} setValue={undefined as any} ref={client} />
            )}
            {stateOffice instanceof Array ? (
              <SelectField
                title='Office Name'
                value={stateOffice}
                selected={selectedOffice}
                setSelected={setSelectedOffice}
                ref={office}
              />
            ) : (
              <TextField type='text' label='Office Name' value={stateOffice} setValue={undefined as any} ref={office} />
            )}
            <SelectField title='Role' value={roleList} selected={selectedRole} setSelected={setSelectedRole} ref={role} />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'User Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostUser,
                  `${baseURL}/api/v1/user`,
                  'POST',
                  {
                    firstName: firstName.current?.value,
                    lastName: lastName.current?.value,
                    email: email.current?.value,
                    clientId: client.current?.innerText || state.client,
                    officeId: office.current?.innerText,
                    userType: role.current?.innerText.toLowerCase(),
                  }
                );

                if (refresh) {
                  resetUser();
                  setRefresh(prev => !prev);
                }
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  ) : (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='two-form-header'>
            <SelectField
              title='Users'
              value={userDropdown}
              ref={userList}
              selected={selectedUser}
              setSelected={setSelectedUser}
            />
            <div
              className='toggle-button'
              onClick={() => {
                resetUser();
                setModify(prev => !prev);
              }}
            >
              <MdEdit />
            </div>
          </div>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <TextField
              type='text'
              label={'First Name'}
              ref={firstName}
              value={firstNameState}
              setValue={setFirstNameState}
              required={true}
            />
            <TextField
              type='text'
              label={'Last Name'}
              ref={lastName}
              value={lastNameState}
              setValue={setLastNameState}
              required={true}
            />
            <TextField type='email' label='Email' ref={email} value={emailState} setValue={setEmailState} required={true} />
            {stateClient instanceof Array ? (
              <SelectField
                title='Client Name'
                value={stateClient}
                selected={selectedClient}
                setSelected={setSelectedClient}
                ref={client}
                required={true}
              />
            ) : (
              <TextField type='text' label='Client Name' value={stateClient} setValue={undefined as any} ref={client} />
            )}
            {stateOffice instanceof Array ? (
              <SelectField
                title='Office Name'
                value={stateOffice}
                selected={selectedOffice}
                setSelected={setSelectedOffice}
                ref={office}
              />
            ) : (
              <TextField type='text' label='Office Name' value={stateOffice} setValue={undefined as any} ref={office} />
            )}
            <SelectField title='Role' value={roleList} selected={selectedRole} setSelected={setSelectedRole} ref={role} />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Delete'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'User Removed Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  () => Promise.resolve(true),
                  `${baseURL}/api/v1/user/${userDropdown[selectedUser]}`,
                  'DELETE',
                  {
                    firstName: firstName.current?.value,
                    lastName: lastName.current?.value,
                    email: email.current?.value,
                    clientId: client.current?.innerText || state.client,
                    officeId: office.current?.innerText,
                    userType: role.current?.innerText.toLowerCase(),
                  }
                );
                if (refresh) {
                  setSelectedUser(0);
                  resetUser();
                  setRefresh(prev => !prev);

                  if (userDropdown.length === 1) {
                    setModify(prev => !prev);
                    setNotification({ message: '', type: 'hidden' });
                  }
                }
                return Promise.resolve();
              }}
            />
            <Button
              type='Update'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'User Updated Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostUser,
                  `${baseURL}/api/v1/user/${userDropdown[selectedUser]}`,
                  'PUT',
                  {
                    firstName: firstName.current?.value,
                    lastName: lastName.current?.value,
                    email: email.current?.value,
                    clientId: client.current?.innerText || state.client,
                    officeId: office.current?.innerText,
                    userType: role.current?.innerText.toLowerCase(),
                  }
                );
                if (refresh) {
                  setRefresh(prev => !prev);
                }
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(User);
