import validator from 'validator';
import { INotification, SANITIZE_CHECK, powerbi_format } from '../static/interfaces/globalInterfaces';

export async function validatePostPrincipalToken(
  data: any,
  setNotification: (notification: INotification) => void
): Promise<boolean> {
  return new Promise((resolve, _) => {
    let errors: string[] = [];
    if (!data.name) errors.push('A Principal Token Name is required');
    if (!validator.isLength(data.name, { min: 2, max: 200 }))
      errors.push('A Principal Token Name must be between 2 and 200 characters');
    if (SANITIZE_CHECK.test(data.name)) errors.push('A Principal Token Name contains invalid characters');
    if (!data.tokenId) errors.push('A Token Id is required');
    if (!data.tokenSecret) errors.push('A Token Secret is required');
    if (!data.tenantId) errors.push('A Tenant Id is required');
    if (!data.clientId || data.clientId === 'Please Select a Client') errors.push('Client is required');

    if (errors.length > 0) {
      setNotification({ message: errors.join('\n'), type: 'error' });
      return resolve(false);
    } else {
      return resolve(true);
    }
  });
}

export async function validatePostReport(
  data: any,
  setNotification: (notification: INotification) => void
): Promise<boolean> {
  return new Promise((resolve, _) => {
    let errors: string[] = [];

    if (!data.name) errors.push('A Report Name is required');
    if (!validator.isLength(data.name, { min: 2, max: 200 }))
      errors.push('A Report Name must be between 2 and 200 characters');
    if (SANITIZE_CHECK.test(data.name)) errors.push('A Report Name contains invalid characters');
    if (!data.reportId) errors.push('A Report Id is required');
    if (!validator.matches(data.reportId, powerbi_format)) errors.push('Report Id format is invalid');
    if (!data.groupId) errors.push('A Group Id is required');
    if (!validator.matches(data.groupId, powerbi_format)) errors.push('Group Id format is invalid');
    if (!data.clientId || data.clientId === 'Please select a Client') errors.push('Client is required');
    if (!data.principalToken || data.principalToken === 'Please select a Token') errors.push('Principal Token is required');

    if (errors.length > 0) {
      setNotification({ message: errors.join('\n'), type: 'error' });
      return resolve(false);
    } else {
      return resolve(true);
    }
  });
}
