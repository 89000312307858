import React, { useState } from 'react';

interface IProps {
  type: 'text' | 'email' | 'password';
  value?: string | number;
  label: string;
  disabled?: boolean;
  required?: boolean;
}

const TextField = React.forwardRef<HTMLInputElement, IProps>(({ type, label, value, required, disabled }, ref) => {
  const [text, setText] = useState(value || '');

  return (
    <div className='form-div'>
      <input
        type={type}
        className={disabled ? 'form-input inactive' : 'form-input'}
        value={text}
        placeholder={' '}
        ref={ref}
        disabled={disabled}
        onChange={e => setText(e.target.value)}
      />
      <label className='form-label'>{label}</label>
      {required && <div className='required_indicator'>*</div>}
    </div>
  );
});

export default TextField;
