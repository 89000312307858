import { createGlobalStyle } from 'styled-components';
import { Type } from 'react-tooltip';

/**Default setup for application layout and dynamic color theme */
export const GlobalStyle = createGlobalStyle`
    *, ::before, ::after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        user-select: text;
    }
    html{
        font-family: 'Lato', Arial, sans-serif;
        font-size: 17.5px;
    }
    .app{
        display: flex;
    }
    body{
        background-color: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
    }
    .dropdown_menu, .dropdown_menu a:focus, .dropdown_menu a:visited{
        color: ${({ theme }) => theme.text};
    }
    .load_word span{
        color: ${({ theme }) => theme.text};
    }
    .load_spinner .spin{
        color: ${({ theme }) => theme.highlight};
    }
    .bounce_container div{
        background: ${({ theme }) => `linear-gradient(${theme.text}, ${theme.highlight})`};
    }
    .link, .link a:focus, .link a:visited{
        color: ${({ theme }) => theme.text};
    }
    #expand_arrow:hover{
        color: ${({ theme }) => theme.highlight};
        transition: .2s;
    }
    .user_dropdown .dropdown{
        background-color: ${({ theme }) => theme.primary_main};
        border-bottom: 1px solid ${({ theme }) => theme.primary_accent};
    }
    .user_dropdown .dropdown_label:hover > svg{
       color: ${({ theme }) => theme.highlight};
       transition: .2s;
    }
    .user_dropdown .dropdown_menu{
        background-color: ${({ theme }) => theme.primary_main};
        border: 1px solid ${({ theme }) => theme.primary_accent};
    }
    .user_dropdown .dropdown_item:hover{
        background-color: ${({ theme }) => theme.highlight};
    }
    .user_dropdown .dropdown_logo{
        background-color: ${({ theme }) => theme.secondary_accent};
    }
    .tab_container{
        border: 1px solid ${({ theme }) => theme.tab_border};
    }
    .tab_container{
        border-bottom: 1px solid ${({ theme }) => theme.tab_border};
    }
    .tabs{
        background: ${({ theme }) => theme.primary_accent};
        border-right: 1px solid ${({ theme }) => theme.tab_border};
        border-bottom: 1px solid ${({ theme }) => theme.tab_border};
    }
    .tab_icons a{
        color: ${({ theme }) => theme.text};
    }
    .tab_icons #information:hover, .tab_icons #info_tip:hover, .tab_icons #expand:hover, .tab_icons #refresh:hover{
        color: ${({ theme }) => theme.highlight};
        transition: .2s;
    }
    .active_tabs{
        background: ${({ theme }) => theme.primary_main};
        border-bottom: 1px solid transparent;
    }
    .active_tabs::before{
        background: ${({ theme }) => theme.highlight};
    }
    .content{
        background: ${({ theme }) => theme.primary_main};
    }
    .content h2{
        border-bottom: 2px solid ${({ theme }) => theme.highlight};
    }
    .admin_page{
        width: 100%;
    }
    .form-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .one-form-header{
        width: 40vw;
        max-width: 750px;
        height: 50px;
        display: grid;
        grid-template-columns: auto max-content;

    }
    .two-form-header{
        width: 80vw;
        max-width: 1500px;
        height: 50px;
        display: grid;
        grid-template-columns: auto max-content;

    }
    .toggle-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.primary_main};
        font-size: 1.5rem;
        color: ${({ theme }) => theme.text};
        cursor: pointer;
        transition: .2s;
        margin-left: 1rem;
    }
    .toggle-button:hover{
        color: ${({ theme }) => theme.highlight};
    }
    .form-title{
        display: flex;
        align-items: center;
        font-weight: 200;
        margin-left: .5rem;
    }
    .form-div{
        position: relative;
        height: 3rem;
        margin-bottom: 1.5rem;
    }
    .form-area-div{
        position: relative;
        height: 9rem;
    }
    .selector{
        position: relative;
        margin-bottom: 1.5rem;
        height: 3.1rem;
    }
    .selectorField{
        border: 1px solid ${({ theme }) => theme.text};
    }
    .selector:hover > .selectorField{
        border: 2px solid ${({ theme }) => theme.highlight};
    }
    .selector:hover > .selectorField > svg{
        color: ${({ theme }) => theme.highlight};
    }
    .select-list{
        background-color: ${({ theme }) => (theme.title === 'dark' ? theme.primary_accent : theme.primary_main)};
    }
    .select-list::-webkit-scrollbar-track {
        background: none;
        outline: none;
        border-top-right-radius: .4rem;
    }
    .select-list::-webkit-scrollbar{
        width: .4rem;
        border-top-right-radius: .4rem;
    }
    .select-list::-webkit-scrollbar-thumb {
        border-top-right-radius: .4rem;
        background-color: ${({ theme }) => (theme.title === 'dark' ? theme.primary_main : theme.tab_border)};             
    }
    .select-item, .checkbox-item{
        border-bottom: 1px solid ${({ theme }) => (theme.title === 'dark' ? 'rgb(255, 255, 255, .2)' : 'rgb(0, 0, 0, .2)')};
    }
    .select-item:hover, .checkbox-item:hover{
        background-color: ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.secondary_accent)};
    }
    .form-area-check-label::before{
        background: ${({ theme }) => theme.text};
        border: 1.5px solid ${({ theme }) => theme.body};
    }
    .form-area-checkbox:checked ~ .form-area-check-label::after{
        background: ${({ theme }) => theme.highlight};
    }
    .form-input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        border-radius: .5rem;
        padding: .5rem;
        z-index: 1;
        outline: none;
        background: none;
    }
    .form-area-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1.3px solid ${({ theme }) => theme.text};
        border-radius: .5rem;
        font-size: 1.4rem;
        outline: none;
        background: none;
    }
    .form-area-list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 1.2rem;
        justify-items: center;
        padding: .6rem;
        list-style: none;
        height: 80%;
        width: 100%;
        background: none;
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
        overflow-y: auto;
    }
    .form-area-list-item{
        height: 1.75rem;
        width: 80%;
        background: ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.tab_border)};
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        border-radius: .5rem;
        padding-left: .3rem;
        cursor: pointer;
        transition: .2s;
        user-select: none;
    }
    .no-click-item{
        cursor: not-allowed;
    }
    .form-area-list-item:hover{
        background: ${({ theme }) => theme.highlight};
    }
    .no-click-item:hover{
        background: ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.tab_border)};
    }
    .form-area-list::-webkit-scrollbar-track {
        background: none;
        outline: none;
        border-top-right-radius: .4rem;
      }
      .form-area-list::-webkit-scrollbar{
        width: .4rem;
        border-top-right-radius: .4rem;
      }
      .form-area-list::-webkit-scrollbar-thumb {
        border-top-right-radius: .4rem;
        background-color: ${({ theme }) =>
          theme.title === 'dark' ? theme.secondary_accent : theme.tab_border};             
      }
    .form-area-input-container{
        display: flex;
        height: 20%;
        width: 100%;
        background: none;
        border-top: 1.5px solid ${({ theme }) => theme.text};
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }
    .form-area-list-item::-webkit-scrollbar-track {
        background: none;
        outline: none;
      }
      .form-area-list-item::-webkit-scrollbar {
        height: .2rem;
      }
      .form-area-list-item::-webkit-scrollbar-thumb {
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
        background-color: white;             
      }
    .form-area-input{
        width: 92%;
        height: 100%;
        border: 0;
        outline: none;
        background: ${({ theme }) => theme.body};
        border-bottom-left-radius: .4rem;
        padding: .5rem;
        font-size: 1.3rem;
        color: ${({ theme }) => theme.text};
    }
    .form-area-button{
        width: 8%;
        height: 100%;
        border: 0;
        outline: none;
        border-bottom-right-radius: .4rem;
        border-left: 1.3px solid ${({ theme }) => theme.text};
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        font-size: 1.2rem;
        cursor: pointer;
        transition: .2s;
    }
    .form-area-button:hover{
        background: ${({ theme }) => theme.highlight};
        cursor: pointer;
    }
    .form-select-div{
        display: flex; 
    }
    .form-select{
        width: 100%;
        height: 100%;
        font-size: 1.3rem;
        border-radius: .5rem;
        padding: .5rem;
        transition: .2s;
        border: 1px solid ${({ theme }) => theme.text};
        color: ${({ theme }) => theme.text};
        outline: none;
        background: none;
        cursor: pointer;
    }
    .form-select:focus{
        border: 1px solid ${({ theme }) => theme.highlight};
    }
    .form-select-div:focus > svg{
        color: ${({ theme }) => theme.highlight};
        transition: .2s;
    }
    .form-select-icon{
        position: absolute;
        top: .5rem;
        right: 1.5rem;
        height: 1.7rem;
    }
    .form-label{
        position: absolute;
        left: .75rem;
        top: .5rem;
        padding: 0 .3rem;
        font-size: 1.2rem;
        background: none;
    }
    .form-area-label{
        position: absolute;
        left: .75rem;
        top: -.9rem;
        padding: 0 .3rem;
        font-size: 1.2rem;
        background: none;
    }
    .form-area-label{
        position: absolute;
        left: .9rem;
        top: -.9rem;
        padding: 0 .3rem;
        font-size: 1.2rem;
        z-index: 1;
        background-color: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
    }
    .form-area-label.inactive{
        color: ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.tab_border)};
    }
    .form-area-check-container{
        position: relative;
        display: flex;
    }
    .check-area-icon{
        position: absolute;
        top: 0;
        height: 1.7rem;
        width: 100%;
        cursor: pointer;
        border: 1.5px solid ${({ theme }) => theme.text};
        border-bottom: none;
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
        background: none;
        outline: none;
    }
    .check-area-icon.inactive{
        cursor: not-allowed;
        color: ${({ theme }) => theme.text};
    }
    .check-area-icon:hover{
        background-color: ${({ theme }) => theme.highlight};
    }
    .check-area-icon.inactive:hover{
        background: none;
    }
    .form-area-check-list{
        transform: translateY(1.9rem);
        list-style: none;
    }
    .form-area-check-list.inactive{
        height: 0;
        display: none;
    }
    .form-input:focus + .form-label{
        top: -.7rem;
        left: .8rem;
        font-size: 1.1rem;
        font-weight: 500;
        z-index: 10;
    }
    .form-input:not(:placeholder-shown).form-input:not(:focus)+ .form-label{
        top: -.7rem;
        left: .8rem;
        font-size: 1.1rem;
        font-weight: 500;
        z-index: 10;
    }
    .selectorField-title{
        color: ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.tab_border)};
        background-color: ${({ theme }) => theme.body};
    }
    .selector:hover > .selectorField > .selectorField-title{
        color: ${({ theme }) => theme.highlight}
    }
    .navbar{
        background-color: ${({ theme }) => (theme.title === 'dark' ? theme.primary_accent : theme.primary_main)}
    }
    .navbar a{
        color: ${({ theme }) => theme.text};
        text-decoration: none;
        transition: all .2s;
    }
    .navbar a:hover{
        background-color:  ${({ theme }) => (theme.title === 'dark' ? theme.primary_main : theme.secondary_accent)};
    }
    .navbar a:visited, .navbar a:focus, {
        color: ${({ theme }) => theme.text};
    }
    .nav-submenu{
        background-color:  ${({ theme }) => (theme.title === 'dark' ? theme.primary_accent : theme.primary_main)};
    }
    .nav-submenu a{
        color: ${({ theme }) => theme.text};
        transition: all .2s;
    }
    .nav-submenu a:hover{
        background-color:  ${({ theme }) => (theme.title === 'dark' ? theme.primary_main : theme.secondary_accent)};
    }
    .nav-submenu a:visited, .nav-submenu a:focus, {
        color: ${({ theme }) => theme.text};
    }
    .page{
        width: 100%;
        margin: .5rem .5rem 0 .25rem;
    }
    .admin_page{
        border-left: 1px solid ${({ theme }) => theme.text};
    }
    .admin_body{
        padding: 2rem;
    }
    .one_column_form{
        margin-top: 1.5rem;
        width: 40vw;
        max-width: 750px;
        padding: 1.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
    }
    .one-column-button-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
        width: 40vw;
        max-width: 750px;
        margin-top: 1.5rem;
    }
    .two-column-form{
        margin-top: 1.5rem;
        width: 80vw;
        max-width: 1500px;
        padding: 1.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: .5rem;
    }
    .two-column-button-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
        width: 80vw;
        max-width: 1500px;
        margin-top: 1.5rem;
    }
    .one_column_form{
        border: 1px solid ${({ theme }) => theme.text};
        box-shadow: ${({ theme }) =>
          theme.title === 'dark' ? '0 -2px 10px rgba(0, 0, 0, 1)' : '0 10px 25px rgba(92,99,105,.2)'};
    }
    .two-column-form{
        border: 1px solid ${({ theme }) => theme.text};
        box-shadow: ${({ theme }) =>
          theme.title === 'dark' ? '0 -2px 10px rgba(0, 0, 0, 1)' : '0 10px 25px rgba(92,99,105,.2)'};
    }
    .form-input{
        color: ${({ theme }) => theme.text};
        border: 1px solid ${({ theme }) => theme.text};
    }
    .form-input{
        color: ${({ theme }) => theme.text};
        border: 1px solid ${({ theme }) => theme.text};
    }
    .form-label{
        color: ${({ theme }) => theme.text};
        transition: .2s;
    }
    .form-button{
        background-color: ${({ theme }) => theme.text};
        color: ${({ theme }) => theme.body};
    }
    .form-input:focus + .form-label{
        color: ${({ theme }) => theme.highlight};
        background-color: ${({ theme }) => theme.body};
    }
    .form-input:not(:placeholder-shown).form-input:not(:focus) + .form-label{
        color: ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.tab_border)};
        background-color: ${({ theme }) => theme.body};
    }
    .form-input.inactive {
        border: 2px solid ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.tab_border)};
        cursor: not-allowed;
    }
    .form-input:focus{
        border: 2px solid ${({ theme }) => theme.highlight};
    }
    .form-btn.Submit, .form-btn.Update{
        background-color: ${({ theme }) => theme.text};
    }
    .form-btn.Delete{
        background-color: ${({ theme }) => theme.text} ;
    }
    .form-btn span{
        color: ${({ theme }) => theme.body};
    }
    .form-btn.Submit:before, .form-btn.Submit:after, .form-btn.Update:before, .form-btn.Update:after {
        background: ${({ theme }) => theme.highlight};
    }
    .form-btn.Delete:before, .form-btn.Delete:after {
        background: ${({ theme }) => (theme.title === 'dark' ? 'rgb(138, 11, 11)' : 'rgb(219, 9, 9)')};
    }
    .form-btn:hover span {
        color: ${({ theme }) => (theme.title === 'dark' ? theme.text : theme.body)};
    }
    .required_indicator{
        font-size: 1.5rem;
        position: absolute;
        top: -.6rem;
        left: -.15rem;
        height: 1rem;
        width: .8rem;
        z-index: 2;
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.highlight};
    }
    .form-input:focus + .form-label + .required_indicator{
        z-index: -1;
        opacity: 0;
    }
    .form-input:not(:placeholder-shown).form-input:not(:focus) ~ .required_indicator{
        color: ${({ theme }) => (theme.title === 'dark' ? theme.secondary_accent : theme.tab_border)};
    }
    .selector:hover > .required_indicator{
        z-index: -1;
        opacity: 0;
     }
    .notification_banner{
        padding: .7rem;
        border-radius: .5rem;
        width: 80vw;
        max-width: 1800px;
        margin: 2rem auto 0 auto;
        display: flex;
        justify-content: space-evenly;
        font-size: 1.3rem;
        line-height: 2.5rem;
        white-space:pre-wrap;
        max-height: 11rem;
        overflow: hidden;
    }
    .success_banner{
        background-color: ${({ theme }) => theme.highlight}; //'rgb(15, 82, 15)' : 'rgb(27, 168, 27)'
        color: ${({ theme }) => theme.text};
    }
    .error_banner{
        background-color: ${({ theme }) => (theme.title === 'dark' ? 'rgb(138, 11, 11)' : 'rgb(219, 9, 9)')};
        color: ${({ theme }) => theme.text};
    }
    footer a:hover{
        color:  ${({ theme }) => theme.highlight};
        transition: .2s;
    }
`;

declare module 'styled-components' {
  interface DefaultTheme extends dashboardTheme {}
}

export interface dashboardTheme {
  title: Type;
  body: string;
  text: string;
  primary_main: string;
  primary_accent: string;
  secondary_accent: string;
  highlight: string;
  tab_border: string;
}

const light_base_color = '#f5f5f5';
const light_text_color = '#070707';
const light_primary_color = '#c7c7c7';
const light_primary_accent_color = '#ececec';
const light_secondary_accent_color = '#e8e8e8';
const light_highlight_color = '#219cd5';
const light_tab_border_color = '#676767';

const dark_base_color = '#242526';
const dark_text_color = '#fff';
const dark_primary_main_color = '#333';
const dark_primary_accent_color = '#474a4d';
const dark_secondary_accent_color = '#5a5c5d';
const dark_highlight_color = '#154ba1';
const dark_tab_border_color = '#010124';

export const lightTheme: dashboardTheme = {
  title: 'light',
  body: light_base_color,
  text: light_text_color,
  primary_main: light_primary_color,
  primary_accent: light_primary_accent_color,
  secondary_accent: light_secondary_accent_color,
  highlight: light_highlight_color,
  tab_border: light_tab_border_color,
};

export const darkTheme: dashboardTheme = {
  title: 'dark',
  body: dark_base_color,
  text: dark_text_color,
  primary_main: dark_primary_main_color,
  primary_accent: dark_primary_accent_color,
  secondary_accent: dark_secondary_accent_color,
  highlight: dark_highlight_color,
  tab_border: dark_tab_border_color,
};
