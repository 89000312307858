import { DefaultTheme } from 'styled-components';
import { KeycloakInstance } from 'keycloak-js';
import { validatePostUser } from '../../validations/user';
export interface IReport {
  name: string;
  reportId: string;
  groupId: string;
  role: string;
  principalToken: string;
}
export interface IPowerBiParams {
  embed_url: string;
  report_name: string;
  report_id: string;
  token: string;
  token_expiration: Date;
}

export interface IGlobalUserState {
  firstName: string;
  lastName: string;
  email: string;
  userType: string;
  principalToken_expiration: number;
  timeout: NodeJS.Timeout | null;
  reportList: IReport[];
  client?: string;
  office?: string;
  keycloak: KeycloakInstance;
  theme: boolean;
  dashboard: DefaultTheme;
  csrfToken: string;
  jwtToken: string;
  logo: string;
  activeTab: number;
  tab: IPowerBiParams[];
  refresh: number;
  loaded: boolean;
  dropdownOpen: boolean;
  expandSidebar: boolean;
  dropdownHeight: number;
}

export interface INotification {
  message: string;
  type: string;
}

export type IDisplayNotification = React.Dispatch<React.SetStateAction<INotification>>;

export interface IEvents {
  success_request: () => void;
  fail_request: (value: React.SetStateAction<INotification>) => void;
  hide_banner: () => void;
  disable_buttons: () => void;
  enable_buttons: () => void;
  dispatch_events: (jwt: any, result?: any) => void;
}

export type IValidateFunction = typeof validatePostUser;

export const SANITIZE_CHECK = /[*|<>[\]`\\/();&=]/g;
export const powerbi_format = '[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+';
