import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { CondenseName } from './DropdownLogic';
import { Link } from 'react-router-dom';
import { dispatchOptions } from '../GlobalState/GlobalStateLogic';
import { StateContext } from '../GlobalState/GlobalState';

export const Dropdown: FunctionComponent = ({ children }) => {
  return (
    <div className='dropdown'>
      <ul className='dropdown_nav'>{children}</ul>
    </div>
  );
};

export const DropdownLabel: FunctionComponent = ({ children }) => {
  const { state, dispatch } = useContext(StateContext);
  let [first_name, last_name] = CondenseName(`${state.firstName} ${state.lastName}`);

  return (
    <li className='dropdown_container'>
      {!state.dropdownOpen ? (
        <span
          className='dropdown_label'
          onClick={() => dispatch({ type: dispatchOptions.SetDropdown, payload: !state.dropdownOpen })}
        >
          {first_name}&nbsp;&nbsp;{last_name}
          {<FaCaretDown />}
        </span>
      ) : (
        <span
          className='dropdown_label'
          onClick={() => dispatch({ type: dispatchOptions.SetDropdown, payload: !state.dropdownOpen })}
        >
          {first_name}&nbsp;&nbsp;{last_name}
          {<FaCaretUp />}
        </span>
      )}
      {state.dropdownOpen && children}
    </li>
  );
};

export const DropdownMenu: FunctionComponent = ({ children }) => {
  const { dispatch } = useContext(StateContext);
  const dropDownHeight: any = useRef(0);

  useEffect(() => {
    dispatch({ type: dispatchOptions.SetDropdownHeight, payload: dropDownHeight.current.clientHeight });
    return () => dispatch({ type: dispatchOptions.SetDropdownHeight, payload: 0 });
  }, [dispatch]);

  return (
    <div ref={dropDownHeight} className='dropdown_menu'>
      {children}
    </div>
  );
};

export const DropdownItem = (prop: any) => {
  return prop.link ? (
    <>
      <Link to={prop.link}>
        <label className='dropdown_item' onClick={prop.onClick}>
          <div className='dropdown_logo'> {prop.logo} </div>
          <span className='dropdown_title'>{prop.title} </span>
        </label>
      </Link>
    </>
  ) : (
    <>
      <label className='dropdown_item' onClick={prop.onClick}>
        <div className='dropdown_logo'> {prop.logo} </div>
        <span className='dropdown_title'> {prop.title} </span>
      </label>
    </>
  );
};

interface INavList {
  list: {
    id: number;
    title: string;
    path: string;
  }[];
}

export const NavDropdownItem: FunctionComponent<INavList> = ({ list }) => {
  const [subMenu, setSubMenu] = useState(true);

  return (
    <>
      <ul className={subMenu ? 'nav-submenu' : 'nav-submenu inactive'} onClick={() => setSubMenu(false)}>
        {list.map(nav => {
          return (
            <li className='menu-items' key={nav.id} onClick={() => setSubMenu(false)}>
              <Link to={nav.path}>{nav.title}</Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};
