import { RoleType } from '../../interfaces/login';

export function rlsDropdownItems(role: string) {
  const list = [
    {
      id: 1,
      title: 'Manage RLS Roles',
      path: '/rls-role',
    },
    {
      id: 2,
      title: 'Manage RLS Values',
      path: '/rls-value',
    },
  ];

  if (role === RoleType.Superuser) return list;
  return [];
}
