import React from 'react';
interface IProps {
  notification: {
    message: string;
    type: string;
  };
}
const NotificationBanner: React.FC<IProps> = ({ notification }) => {
  return (
    <>
      {notification.type !== 'hidden' ? (
        notification.type === 'success' ? (
          <div className='notification_banner success_banner'>{notification.message}</div>
        ) : (
          <div className='notification_banner error_banner'>{notification.message}</div>
        )
      ) : null}
    </>
  );
};

export default NotificationBanner;
