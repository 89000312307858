import React from 'react';

interface IProps {
  type: 'text' | 'email' | 'password';
  value?: string | number;
  setValue: (value: string) => void;
  label: string;
  disabled?: boolean;
  required?: boolean;
}

const TextField = React.forwardRef<HTMLInputElement, IProps>(({ type, label, value, setValue, required, disabled }, ref) => {
  return (
    <div className='form-div'>
      <input
        type={type}
        className={disabled ? 'form-input inactive' : 'form-input'}
        value={value}
        placeholder={' '}
        ref={ref}
        disabled={disabled}
        onChange={e => setValue(e.target.value)}
      />
      <label className='form-label'>{label}</label>
      {required && <div className='required_indicator'>*</div>}
    </div>
  );
});

export default TextField;
