/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { dispatchOptions } from '../components/GlobalState/GlobalStateLogic';
import { IGlobalUserState, IEvents, IDisplayNotification, IValidateFunction } from '../static/interfaces/globalInterfaces';
import { instanceOfAxiosError } from '../helpers/axiosTypeGuard';
import { useContext, useEffect, useState } from 'react';
import { StateContext } from '../components/GlobalState/GlobalState';

export const baseURL = `${window.location.protocol}//${window.location.hostname}:${
  window.location.port === '3000' ? '5000' : window.location.port
}`;

axios.interceptors.response.use(
  async res => Promise.resolve(res),
  async error => {
    if (instanceOfAxiosError(error)) {
      const response = error.response;
      // const config = response?.config
      if (response?.status === 403) {
        try {
          // const result = await axios(config?.url as string,{
          //     method: config?.method,
          //     headers: config?.headers,
          //     data: config?.data,
          //     withCredentials: true
          // })

          return Promise.reject(response);
        } catch (err) {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

function NotificationParser(setNotification: IDisplayNotification, err?: AxiosError['response']) {
  switch (err?.status) {
    case 422:
      setNotification({
        message: err?.data.errors.map((val: any) => val.msg).join('\n'),
        type: 'error',
      });
      break;
    case 409:
      setNotification({ message: err?.data.message, type: 'error' });
      break;
    default:
      setNotification({ message: 'An error occurred', type: 'error' });
      break;
  }
}

export const login = async (sub: string) => {
  try {
    const res = await axios.get(`${baseURL}/api/login`, {
      headers: { Authorization: JSON.stringify({ sub }) },
      withCredentials: true,
    });
    const user = res.data;
    const { 'x-token': jwtToken } = res.headers;
    const { 'x-csrf-token': csrfToken } = res.headers;

    return Promise.resolve([user, { csrfToken, jwtToken }]);
  } catch (e) {
    return Promise.reject(e);
  }
};

export async function sendGetRequest(state: IGlobalUserState, dispatch: any, url: string) {
  try {
    const res = await axios(url, {
      method: 'GET',
      headers: {
        'X-Token': state.jwtToken,
      },
      withCredentials: true,
    });

    const result = await res.data;
    const { 'x-token': jwt } = res.headers;

    dispatch({ type: dispatchOptions.SetJWT, payload: jwt });

    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function sendRequestV1(
  events: IEvents,
  state: IGlobalUserState,
  validate: IValidateFunction,
  url: string,
  method: AxiosRequestConfig['method'],
  data?: any
) {
  events.hide_banner();
  events.disable_buttons();
  let flag = true;
  try {
    flag = await validate(data, events.fail_request);
  } catch (e) {
    console.error(e);
    flag = false;
  }
  if (flag) {
    try {
      const res = await axios(url, {
        method,
        headers: { 'X-Token': state.jwtToken },
        data,
        withCredentials: true,
      });

      const { 'x-token': jwt } = res.headers;
      const result = res.data;

      events.dispatch_events(jwt, result);
      events.success_request();
      events.enable_buttons();

      return Promise.resolve(true);
    } catch (err: any) {
      NotificationParser(events.fail_request, err?.response);
      events.enable_buttons();
      return Promise.resolve(err);
    }
  } else {
    events.enable_buttons();
  }
}

export function useRetrieveReportTokens(mount: boolean): [NodeJS.Timeout | null, boolean] {
  const { state, dispatch } = useContext(StateContext);
  const [timeout, setTimeout] = useState<NodeJS.Timeout | null>(null);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (mount) {
      (async () => {
        try {
          const res = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/report/token`);
          const timeout = setInterval(
            () => sendGetRequest(state, dispatch, `${baseURL}/api/v1/report/token`),
            res?.expiration_time || 3500000
          );
          setTimeout(timeout);

          dispatch({
            type: dispatchOptions.SetReportList,
            payload: res.reports,
          });
          dispatch({ type: dispatchOptions.SetTimeout, payload: timeout });

          setFinished(true);
        } catch (e) {
          dispatch({
            type: dispatchOptions.SetReportList,
            payload: [],
          });

          setTimeout(null);
          setFinished(true);
        }
      })();
    }
  }, [mount]);

  return [timeout, finished];
}
