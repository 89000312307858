/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { sendGetRequest, sendRequestV1, baseURL } from '../../Hooks/useRequest';
import TextArea from '../../components/TextArea/TextArea';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import SelectField from '../../components/SelectField/SelectField';
import NotificationBanner from '../../components/Notification/Notification';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import ViewField from '../../components/ViewField/ViewField';
import { validatePostRlsRole } from '../../validations/rls';

const RlsRoleCreate = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });
  const [stateReport, setStateReport] = useState(['Please select a Report']);
  const [stateReportList, setStateReportList] = useState({ client: [], office: [] });
  const [selectedReport, setSelectedReport] = useState(-1);

  const roleNames = useRef<HTMLUListElement>(null);
  const report = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await sendGetRequest(state, dispatch, `${baseURL}/api/v1/report`);
        setStateReport(res.map((val: any) => val.name));
        setStateReportList({
          client: res.map((report: any) => report.client),
          office: res.map((report: any) => report.office),
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <h1 className='form-title'>Manage RLS Roles</h1>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <SelectField title='Reports' list={stateReport} indexTracker={setSelectedReport} ref={report} required={true} />
            <ViewField label={'Report Client Name'} value={stateReportList.client[selectedReport] || ''} />
            <ViewField label={'Report Office Name'} value={stateReportList.office[selectedReport] || ''} />
            <TextArea label='Role Names' ref={roleNames} required={true} />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={() =>
                sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'RLS Role Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostRlsRole,
                  `${baseURL}/api/v1/rls/role`,
                  'POST',
                  {
                    names: roleNames.current?.innerText?.split('\n').filter((val: any) => val !== ''),
                    reportId: report.current?.innerText,
                    clientId: stateReportList.client[selectedReport] || '',
                    officeId: stateReportList.office[selectedReport] || '',
                  }
                )
              }
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(RlsRoleCreate);
