import { useKeycloak } from '@react-keycloak/web';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalState from './components/GlobalState/GlobalState';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './Pages/Home/Home';
import './static/styles/unAuth.scss';
import Personal from './Pages/Personal/Personal';
import ProtectedRoute from './components/ProtectedRoute/protectedRoute';
import User from './Pages/User/User';
import Office from './Pages/Office/Office';
import Client from './Pages/Client/Client';
import Report from './Pages/Report/Report';
import PrincipalToken from './Pages/Report/PrincipalToken';
import RlsRoleCreate from './Pages/RLS/RlsRoleCreate';
import RlsValueCreate from './Pages/RLS/RlsValueCreate';
import UserReportAssign from './Pages/User/UserReportAssign';
import { superPrivilege, powerPrivilege, adminPrivilege } from './interfaces/login';

/**Main entry point for the React Application */
const App = () => {
  const { keycloak } = useKeycloak();

  return (
    <div className='app'>
      {!keycloak.authenticated ? (
        <div className='body-unauthenticated'>
          <div>Please Login to view your Dashboard</div>
          <button
            onClick={() => {
              keycloak.login();
            }}
          >
            Login
          </button>
        </div>
      ) : (
        <BrowserRouter>
          <GlobalState keycloak={keycloak}>
            <Sidebar />
            <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/personal' exact component={Personal} />
              <ProtectedRoute path='/client' privileges={superPrivilege} exact component={Client} />
              <ProtectedRoute path='/office' privileges={powerPrivilege} exact component={Office} />
              <ProtectedRoute path='/user' privileges={adminPrivilege} exact component={User} />
              <ProtectedRoute path='/report-user' privileges={adminPrivilege} exact component={UserReportAssign} />
              <ProtectedRoute path='/token' privileges={superPrivilege} exact component={PrincipalToken} />
              <ProtectedRoute path='/report' privileges={superPrivilege} exact component={Report} />
              <ProtectedRoute path='/rls-role' privileges={superPrivilege} exact component={RlsRoleCreate} />
              <ProtectedRoute path='/rls-value' privileges={superPrivilege} exact component={RlsValueCreate} />
            </Switch>
          </GlobalState>
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
