import React from 'react';

interface IProps {
  value?: string | number;
  label: string;
}

const ViewField: React.FC<IProps> = ({ label, value }) => {
  return (
    <div className='form-div'>
      <input type='text' className='form-input inactive' value={value} placeholder={' '} disabled={true} />
      <label className='form-label'>{label}</label>
    </div>
  );
};

export default ViewField;
