/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../components/GlobalState/GlobalState';
import { sendRequestV1, baseURL, sendGetRequest } from '../../Hooks/useRequest';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import SelectField from '../../components/SelectField2/SelectField';
import NotificationBanner from '../../components/Notification/Notification';
import TextField from '../../components/TextField2/TextField';
import SelectArea from '../../components/SelectArea/SelectArea';
import { dispatchOptions } from '../../components/GlobalState/GlobalStateLogic';
import { validatePostUserReport } from '../../validations/user';
import { MdAdd, MdEdit } from 'react-icons/md';

const UserReportAssign = () => {
  const { state, dispatch } = useContext(StateContext);
  const [send, setSend] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'hidden' });
  const [modify, setModify] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const userList = useRef<HTMLInputElement>(null);
  const user = useRef<HTMLInputElement>(null);
  const report = useRef<HTMLInputElement>(null);
  const role = useRef<HTMLInputElement>(null);
  const values = useRef<HTMLUListElement>(null);

  const [userDropdown, setUserDropdown] = useState<any[]>([]);
  const [stateUsers, setStateUsers] = useState([]);
  const [stateEmail, setStateEmail] = useState('');
  const [stateUserClient, setStateUserClient] = useState('');
  const [stateUserOffice, setStateUserOffice] = useState('');
  const [stateUserList, setStateUserList] = useState<any[]>([]);
  const [stateReport, setStateReport] = useState<any[]>([]);
  const [stateValueList, setStateValueList] = useState<string[]>([]);
  const [stateValuesSelected, setStateValuesSelected] = useState<string[]>([]);
  const [stateRoles, setStateRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(-1);
  const [selectedReport, setSelectedReport] = useState(-1);
  const [selectedRole, setSelectedRole] = useState(-1);
  const [fullName, setFullName] = useState('');

  const resetUser = useCallback(() => {
    setStateValuesSelected([]);
    setSelectedUser(0);
    setSelectedReport(0);
    setSelectedRole(0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const [users, reports] = await Promise.all([
          sendGetRequest(state, dispatch, `${baseURL}/api/v1/user`),
          sendGetRequest(state, dispatch, `${baseURL}/api/v1/report?role=true`),
        ]);

        setUserDropdown(users.map((user: any) => user.email));
        setStateUsers(users.map((user: any) => `${user.firstName} ${user.lastName}`));
        setStateUserList(users);
        setStateReport(reports);
        users?.email && setStateEmail(users.email);
        users?.client && setStateUserClient(users.client);
        users?.office && setStateUserOffice(users.office);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const report = stateReport[selectedReport];
        const client = report?.client === ' ' ? '' : report?.client || '';
        const office = report?.office === ' ' ? '' : `&office=${report?.office || ''}`;
        let url = `${baseURL}/api/v1/rls/role/${report?.name}?client=${client}${office}`;
        const roles = client ? await sendGetRequest(state, dispatch, encodeURI(url)) : [];

        setStateRoles(roles);

        const rol = roles.length ? '' : roles[0];
        url = `${baseURL}/api/v1/rls/value/${rol}?report=${report?.name}&client=${client}${office}`;
        const result = client && rol ? await sendGetRequest(state, dispatch, encodeURI(url)) : [];

        setStateValueList(result);
        setStateValuesSelected([]);
      } catch (e) {
        setStateRoles([]);
        setStateValueList([]);
        setStateValuesSelected([]);
      }
    })();
  }, [selectedReport]);

  useEffect(() => {
    (async () => {
      try {
        const report = stateReport[selectedReport];
        const reportName = report?.name === 'Please select a Report' ? '' : report?.name;
        const client = report?.client === ' ' ? '' : report?.client || '';
        const office = report?.office === ' ' ? '' : `&office=${report?.office || ''}`;
        const rol = stateRoles[selectedRole];
        const url = `${baseURL}/api/v1/rls/value/${rol}?report=${reportName}&client=${client}${office}`;
        const result = client && rol ? await sendGetRequest(state, dispatch, encodeURI(url)) : [];

        setStateValueList(result);
        setStateValuesSelected([]);
      } catch (e) {
        setStateValueList([]);
        setStateValuesSelected([]);
      }
    })();
  }, [selectedRole]);

  useEffect(() => {
    stateUserList[selectedUser]?.firstName &&
      setFullName(`${stateUserList[selectedUser].firstName} ${stateUserList[selectedUser].lastName}`);
    stateUserList[selectedUser]?.email && setStateEmail(stateUserList[selectedUser]?.email);
    stateUserList[selectedUser]?.client && setStateUserClient(stateUserList[selectedUser].client);
    stateUserList[selectedUser]?.office && setStateUserOffice(stateUserList[selectedUser].office);
  }, [stateUserList, selectedUser]);

  return modify ? (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='two-form-header'>
            <h2 className='form-title'>Assign User Report</h2>
            <div
              className='toggle-button'
              onClick={() => {
                if (userDropdown.length > 2) {
                  resetUser();
                  setModify(prev => !prev);
                  setNotification({ message: '', type: 'hidden' });
                } else {
                  setNotification({ message: 'No users to modify', type: 'error' });
                }
              }}
            >
              <MdAdd />
            </div>
          </div>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <SelectField
              title='Users'
              value={stateUsers}
              selected={selectedUser}
              setSelected={setSelectedUser}
              ref={user}
              required={true}
            />
            <TextField label={'User Email'} value={stateEmail} setValue={setStateEmail} type='email' disabled={true} />
            <TextField
              label={'User Client Name'}
              value={stateUserClient}
              type='text'
              setValue={setStateUserClient}
              disabled={true}
            />
            <TextField
              label={'User Office Name'}
              value={stateUserOffice}
              type='text'
              setValue={setStateUserOffice}
              disabled={true}
            />
            <SelectField
              title='Reports'
              value={stateReport.map(report => report.name)}
              selected={selectedReport}
              setSelected={setSelectedReport}
              ref={report}
              required={true}
            />
            <SelectField
              title='RLS Role'
              value={stateRoles}
              selected={selectedRole}
              setSelected={setSelectedRole}
              ref={role}
              required={true}
            />
            <SelectArea
              title='Assign User Report Values'
              list={stateValueList}
              values={stateValuesSelected}
              setValues={setStateValuesSelected}
              ref={values}
              required={true}
            />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Report Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostUserReport,
                  `${baseURL}/api/v1/user/report`,
                  'POST',
                  {
                    email: stateUserList[selectedUser]?.email || '',
                    userClientId: stateUserList[selectedUser]?.client === ' ' ? '' : stateUserList[selectedUser]?.client,
                    userOfficeId: stateUserList[selectedUser]?.office === ' ' ? '' : stateUserList[selectedUser]?.office,
                    reportId: report.current?.innerText === 'Please select a Report' ? '' : report.current?.innerText,
                    reportClientId: stateReport[selectedReport]?.client === ' ' ? '' : stateReport[selectedReport]?.client,
                    reportOfficeId: stateReport[selectedReport]?.office || ' ',
                    rlsRoleId: role.current?.innerText === 'Please select a RLS Role' ? '' : role.current?.innerText,
                    rlsValues: values.current?.innerText === '' ? [] : values.current?.innerText.split('\n'),
                  }
                );

                if (refresh) {
                  resetUser();
                  setRefresh(prev => !prev);
                }
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  ) : (
    <div className='admin_page'>
      <Navbar />
      <div className='admin_body'>
        <div className='form-container'>
          <div className='two-form-header'>
            <SelectField
              title='Users'
              value={userDropdown}
              ref={userList}
              selected={selectedUser}
              setSelected={setSelectedUser}
            />
            <div
              className='toggle-button'
              onClick={() => {
                resetUser();
                setModify(prev => !prev);
              }}
            >
              <MdEdit />
            </div>
          </div>
          <form className='two-column-form' onSubmit={e => e.preventDefault()}>
            <TextField label='Name' value={fullName} setValue={setFullName} type='text' disabled={true} ref={user} />
            <TextField label={'User Email'} value={stateEmail} setValue={setStateEmail} type='email' disabled={true} />
            <TextField
              label={'User Client Name'}
              value={stateUserClient}
              type='text'
              setValue={setStateUserClient}
              disabled={true}
            />
            <TextField
              label={'User Office Name'}
              value={stateUserOffice}
              type='text'
              setValue={setStateUserOffice}
              disabled={true}
            />
            <SelectField
              title='Reports'
              value={stateReport.map(report => report.name)}
              selected={selectedReport}
              setSelected={setSelectedReport}
              ref={report}
              required={true}
            />
            <SelectField
              title='RLS Role'
              value={stateRoles}
              selected={selectedRole}
              setSelected={setSelectedRole}
              ref={role}
              required={true}
            />
            <SelectArea
              title='Assign User Report Values'
              list={stateValueList}
              values={stateValuesSelected}
              setValues={setStateValuesSelected}
              ref={values}
              required={true}
            />
          </form>
          <div className='two-column-button-container'>
            <Button
              type='Submit'
              active={send}
              action={async () => {
                const refresh = await sendRequestV1(
                  {
                    success_request: () => setNotification({ message: 'Report Created Successfully', type: 'success' }),
                    fail_request: (message: any) => setNotification(message),
                    hide_banner: () => setNotification({ message: '', type: 'hidden' }),
                    disable_buttons: () => setSend(true),
                    enable_buttons: () => setSend(false),
                    dispatch_events: (jwt: any) => dispatch({ type: dispatchOptions.SetJWT, payload: jwt }),
                  },
                  state,
                  validatePostUserReport,
                  `${baseURL}/api/v1/user/report`,
                  'POST',
                  {
                    email: stateUserList[selectedUser]?.email || '',
                    userClientId: stateUserList[selectedUser]?.client === ' ' ? '' : stateUserList[selectedUser]?.client,
                    userOfficeId: stateUserList[selectedUser]?.office === ' ' ? '' : stateUserList[selectedUser]?.office,
                    reportId: report.current?.innerText === 'Please select a Report' ? '' : report.current?.innerText,
                    reportClientId: stateReport[selectedReport]?.client === ' ' ? '' : stateReport[selectedReport]?.client,
                    reportOfficeId: stateReport[selectedReport]?.office || ' ',
                    rlsRoleId: role.current?.innerText === 'Please select a RLS Role' ? '' : role.current?.innerText,
                    rlsValues: values.current?.innerText === '' ? [] : values.current?.innerText.split('\n'),
                  }
                );

                if (refresh) {
                  resetUser();
                  setRefresh(prev => !prev);
                }
              }}
            />
          </div>
        </div>
        <NotificationBanner notification={notification} />
      </div>
    </div>
  );
};

export default withRouter(UserReportAssign);
